import React, { ReactChildren, ReactElement } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
// @ts-ignore
import { Loader, LoaderConfig } from '@amzn/awspaloma-ui';
import {
	capturedOrderTotal as capturedOrderTotalSelector,
	isFullDiscount as isFullDiscountSelector,
	isFullDiscountCheckoutService as isFullDiscountSelectorForCheckoutService,
	isLoadingKey as isLoadingKeySelector,
	state as stateSelector,
} from './state/Checkout.selectors';
import { DefaultRootState, useSelector } from 'react-redux';

import styles from './Checkout.summary.styles';
import { AwsSession, CartItem, PaymentState } from './Checkout.utils';
import { TrainingProvider } from '../../lib/enums';

import {
	discountLabel,
	estimatedTaxLabel,
	orderSummary,
	priceLabel,
	taxLabel,
	totalLabel,
} from './Checkout.intl';

// import {
// 	isAuthorizedResellingPlatform,
// 	isKindVilt,
// 	isUsdCurrency,
// 	paymentApplicable,
// } from '../../utils/learningObject';
import { selectors as appSelectors } from '../App/App.module';
import learningObjectKinds from '../../utils/learningObjectKinds';

/**
 * Intl formatNumber magic string setting
 */
const INTL_NUMBER_CURRENCY = 'currency';

/**
 * CheckoutSummary component
 */
const CheckoutSummary = ({
	before = null,
	children,
	cartItem,
	awsSession,
	isLoadingCartSelector,
}: {
	before: ReactElement | null;
	children: ReactElement | ReactChildren;
	cartItem: CartItem;
	awsSession: AwsSession;
	isLoadingCartSelector: string;
}): ReactElement => {
	// Get tax
	const { estimatedTax, price, discountAmount } = cartItem;
	const { kind, resellingPlatform, sellerOfRecord } = awsSession;
	const isCheckoutServiceDisabled = useSelector(
		appSelectors.isFeatureDisabled('CheckoutService') as (
			state: DefaultRootState,
		) => boolean,
	);

	// Verify if discount being used is 100% of the total
	const isFullDiscount: boolean = useSelector(
		isCheckoutServiceDisabled
			? isFullDiscountSelector
			: isFullDiscountSelectorForCheckoutService,
	);
	const isLoadingTax = useSelector(
		isLoadingKeySelector([isLoadingCartSelector, 'tax']) as (
			state: DefaultRootState,
		) => boolean,
	);
	const paymentState: PaymentState = useSelector(stateSelector);
	const capturedOrderTotal = useSelector(capturedOrderTotalSelector) as number;

	const isVILT =
		awsSession !== null &&
		awsSession.kind === learningObjectKinds.VirtualIltSession;

	// Get currency code
	const currencyCode = sellerOfRecord.currency.code;

	const tax =
		paymentState !== PaymentState.PAID
			? estimatedTax || 0
			: capturedOrderTotal - price + discountAmount;

	const total =
		paymentState !== PaymentState.PAID
			? price + tax - discountAmount
			: capturedOrderTotal;

	// Show -- for tax and estimated total
	const showVILTPreview =
		isVILT &&
		!isFullDiscount &&
		paymentState !== PaymentState.AUTH &&
		paymentState !== PaymentState.PAID;

	/**
	 * Indicates whether the user should receive the Xvoucher payment experience or not
	 *
	 * @returns Returns {@code true} if it is appropriate to show the Xvoucher payment experience for the LO
	 */
	const shouldShowXvoucher = (
		price: number,
		kind: number,
		currencyCode: string,
		resellingPlatform: number,
		trainingProvider: number,
	): boolean => {
		return true; //Part of Amazon pay deprecation
	};

	return (
		<section className={styles.container} data-testid="CheckoutSummary">
			{before}

			<h2 className={styles.title}>
				<FormattedMessage {...orderSummary} />
			</h2>
			<div className={styles.details}>
				{isLoadingTax ? (
					<div className={styles.detailsLoader}>
						<Loader
							data-testid="CheckoutSummaryPriceLoader"
							data-test-hasloaded={(!isLoadingTax).toString()}
							variant={LoaderConfig.SimpleVariant}
							hasLoaded={!isLoadingTax}
							className={styles.detailsLoaderGfx}
						/>
					</div>
				) : null}

				{/* Before signing into AP for a VILT, we show -- for the tax and totals since they must be calculated after AP login */}
				{showVILTPreview ? (
					<dl className={styles.detailsList}>
						<dt>
							<FormattedMessage {...priceLabel} />
						</dt>
						<dd>
							<span data-testid="CheckoutSummaryPrice">
								<FormattedNumber
									// eslint-disable-next-line react/style-prop-object
									style={INTL_NUMBER_CURRENCY}
									currency={currencyCode}
									value={price}
								/>
							</span>
						</dd>
						<dt>
							<FormattedMessage {...estimatedTaxLabel} />
						</dt>
						<dd>
							<span data-testid="CheckoutSummaryTax">--</span>
						</dd>
					</dl>
				) : (
					<dl className={styles.detailsList}>
						<dt>
							<FormattedMessage {...priceLabel} />
						</dt>
						<dd>
							<span data-testid="CheckoutSummaryPrice">
								<FormattedNumber
									// eslint-disable-next-line react/style-prop-object
									style={INTL_NUMBER_CURRENCY}
									currency={currencyCode}
									value={price}
								/>
							</span>
						</dd>
					</dl>
				)}

				{!shouldShowXvoucher(
					price,
					kind,
					currencyCode,
					resellingPlatform,
					TrainingProvider.AmazonWebServices,
				) && !showVILTPreview ? (
					<dl className={styles.detailsList}>
						<dt>
							<FormattedMessage {...taxLabel} />
						</dt>
						<dd>
							<span data-testid="CheckoutSummaryTax">
								<FormattedNumber
									// eslint-disable-next-line react/style-prop-object
									style={INTL_NUMBER_CURRENCY}
									currency={currencyCode}
									value={tax}
								/>
							</span>
						</dd>
					</dl>
				) : null}

				{shouldShowXvoucher(
					price,
					kind,
					currencyCode,
					resellingPlatform,
					TrainingProvider.AmazonWebServices,
				) && !showVILTPreview ? (
					<dl className={styles.detailsList}>
						<dt>
							<FormattedMessage {...taxLabel} />
						</dt>
						<dd>
							<span data-testid="CheckoutSummaryXvoucherCompletedTax">
								<FormattedNumber
									// eslint-disable-next-line react/style-prop-object
									style={INTL_NUMBER_CURRENCY}
									currency={currencyCode}
									value={tax}
								/>
							</span>
						</dd>
					</dl>
				) : null}

				{discountAmount ? (
					<dl className={styles.detailsList}>
						<dt>
							<FormattedMessage {...discountLabel} />
						</dt>
						<dd>
							<span data-testid="CheckoutSummaryDiscount">
								<FormattedNumber
									// eslint-disable-next-line react/style-prop-object
									style={INTL_NUMBER_CURRENCY}
									currency={currencyCode}
									value={discountAmount}
								/>
							</span>
						</dd>
					</dl>
				) : null}

				{showVILTPreview ? (
					<dl className={styles.detailsList}>
						<dt>
							<strong>
								<FormattedMessage {...totalLabel} />
							</strong>
						</dt>
						<dd>
							<strong data-testid="CheckoutSummaryTotal">--</strong>
						</dd>
					</dl>
				) : null}
				{!showVILTPreview ? (
					<dl className={styles.detailsList}>
						<dt>
							<strong>
								<FormattedMessage {...totalLabel} />
							</strong>
						</dt>
						<dd>
							<strong data-testid="CheckoutSummaryTotal">
								<FormattedNumber
									// eslint-disable-next-line react/style-prop-object
									style={INTL_NUMBER_CURRENCY}
									currency={currencyCode}
									value={total as number}
								/>
							</strong>
						</dd>
					</dl>
				) : null}
			</div>
			{children}
		</section>
	);
};

export default CheckoutSummary;
